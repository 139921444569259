<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$l('elevator.batchModification','系统参数')" name="first">
      <sys-item-list></sys-item-list>
    </el-tab-pane>
    <el-tab-pane :label="$l('elevator.faultTemplate','隐私政策')" name="second">
      <sys-config-list></sys-config-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import SysItemList from "@/views/sysItem/SysItemList";
  import SysConfigList from "@/views/sysItem/SysConfigList";

  export default {
    components: {SysItemList,SysConfigList},
    data() {
      return {
        activeName: "first",
      };
    },
    methods:{
      handleClick(tab, event) {
        console.log(tab, event);
      },
    }
  };
</script>

<style scoped>

</style>
