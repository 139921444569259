<template>
  <div v-loading="loading" style="width: 100%">
    <el-form
      ref="formValidate"
      class="form"
      label-width="160px"
      :model="eocdSettingParam"
      style="padding-right: 20px; margin-top: 5vh;">
      <div class="vm-separate">
        <el-form-item label="ISO垂直方向（PkPk）" label-width="160px" prop="param2">
          <el-input type="number" v-model="eocdSettingParam.param2">
            <template slot="suffix">milli(g)</template>
          </el-input>
        </el-form-item>
        <div></div>
      </div>
      <div class="vm-separate">
        <el-form-item label="ISO垂直方向（A95）" label-width="160px" prop="param4">
          <el-input type="number" v-model="eocdSettingParam.param4">
            <template slot="suffix">milli(g)</template>
          </el-input>
        </el-form-item>
        <div></div>
      </div>
      <div class="vm-separate">
        <el-form-item label="ISO水平方向（PkPk）" label-width="160px" prop="param1">
          <el-input type="number" v-model="eocdSettingParam.param1">
            <template slot="suffix">milli(g)</template>
          </el-input>
        </el-form-item>
        <div></div>
      </div>
      <div class="vm-separate">
        <el-form-item label="ISO水平方向（A95）" label-width="160px" prop="param3">
          <el-input type="number" v-model="eocdSettingParam.param3">
            <template slot="suffix">milli(g)</template>
          </el-input>
        </el-form-item>
        <div></div>
      </div>
      <div class="vm-separate">
        <el-form-item label="低通滤波水平方向（PkPk）" label-width="160px" prop="param5">
          <el-input type="number" v-model="eocdSettingParam.param5">
            <template slot="suffix">milli(g)</template>
          </el-input>
        </el-form-item>
        <div></div>
      </div>
      <div class="vm-separate">
        <el-form-item label="低通滤波垂直方向（PkPk）" label-width="160px" prop="param6">
          <el-input type="number" v-model="eocdSettingParam.param6">
            <template slot="suffix">milli(g)</template>
          </el-input>
        </el-form-item>
        <div></div>
      </div>
      <div class="vm-separate">
        <el-form-item label="低通滤波赫兹（HZ）" label-width="160px" prop="param7">
          <el-input type="number" v-model="eocdSettingParam.param7">
            <template slot="suffix">milli(g)</template>
          </el-input>
        </el-form-item>
        <div></div>
      </div>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        style="margin-left: 15%"
        @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </el-form>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      systemCode: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        loading: true,
        submitLoading: false,
        saveDisabled: false,
        eocdSettingParam: {
          param1: 0,
          param2: 0,
          param3: 0,
          param4: 0,
          param5: 0,
          param6: 0,
          param7: 0,
        },

        ruleValidate: {
          param1: [
            {
              type: "number",
              min: 0,
              message: this.$l("maintWorkOrder.setting.distanceTip", "请输入一个大于0的值"),
              trigger: "blur",
            },
          ],
          param2: [
            {
              type: "number",
              min: 0,
              message: this.$l("maintWorkOrder.setting.distanceTip", "请输入一个大于0的值"),
              trigger: "blur",
            },
          ],
          param3: [
            {
              type: "number",
              min: 0,
              message: this.$l("maintWorkOrder.setting.distanceTip", "请输入一个大于0的值"),
              trigger: "blur",
            },
          ],
          param4: [
            {
              type: "number",
              min: 0,
              message: this.$l("maintWorkOrder.setting.distanceTip", "请输入一个大于0的值"),
              trigger: "blur",
            },
          ],
          param5: [
            {
              type: "number",
              min: 0,
              message: this.$l("maintWorkOrder.setting.distanceTip", "请输入一个大于0的值"),
              trigger: "blur",
            },
          ],
          param6: [
            {
              type: "number",
              min: 0,
              message: this.$l("maintWorkOrder.setting.distanceTip", "请输入一个大于0的值"),
              trigger: "blur",
            },
          ],
          param7: [
            {
              type: "number",
              min: 0,
              message: this.$l("maintWorkOrder.setting.distanceTip", "请输入一个大于0的值"),
              trigger: "blur",
            },
          ],
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.loading = false;
        this.$api.getData("companySettings/sys/vibrometer").then(res => {
          let rows = res.data;
          console.log(res.data);
          if (rows.length > 0) {
            rows.forEach(item => {
              if (item.settingType === 101) {
                this.eocdSettingParam.param1 = item.settingValue;
              }
              if (item.settingType === 102) {
                this.eocdSettingParam.param2 = item.settingValue;
              }
              if (item.settingType === 103) {
                this.eocdSettingParam.param3 = item.settingValue;
              }
              if (item.settingType === 104) {
                this.eocdSettingParam.param4 = item.settingValue;
              }
              if (item.settingType === 105) {
                this.eocdSettingParam.param5 = item.settingValue;
              }
              if (item.settingType === 106) {
                this.eocdSettingParam.param6 = item.settingValue;
              }
              if (item.settingType === 107) {
                this.eocdSettingParam.param7 = item.settingValue;
              }
            });
          }
        }).catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      handleCustom() {
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            let eocdSetting = [];
            let item1 = {settingType: 101, settingValue: this.eocdSettingParam.param1};
            let item2 = {settingType: 102, settingValue: this.eocdSettingParam.param2};
            let item3 = {settingType: 103, settingValue: this.eocdSettingParam.param3};
            let item4 = {settingType: 104, settingValue: this.eocdSettingParam.param4};
            let item5 = {settingType: 105, settingValue: this.eocdSettingParam.param5};
            let item6 = {settingType: 106, settingValue: this.eocdSettingParam.param6};
            let item7 = {settingType: 107, settingValue: this.eocdSettingParam.param7};
            eocdSetting.push(item1);
            eocdSetting.push(item2);
            eocdSetting.push(item3);
            eocdSetting.push(item4);
            eocdSetting.push(item5);
            eocdSetting.push(item6);
            eocdSetting.push(item7);
            this.$http.post("companySettings/sys/save", eocdSetting).then(() => {
              this.submitLoading = false;
              this.$message.success(this.$t("common.tip.saveSuccess"));
              this.getData();
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
