import { render, staticRenderFns } from "./VmRichTextEditor.vue?vue&type=template&id=f3cfd3aa&scoped=true"
import script from "./VmRichTextEditor.vue?vue&type=script&lang=js"
export * from "./VmRichTextEditor.vue?vue&type=script&lang=js"
import style0 from "./VmRichTextEditor.vue?vue&type=style&index=0&id=f3cfd3aa&prod&lang=scss&scoped=true"
import style1 from "./VmRichTextEditor.vue?vue&type=style&index=1&id=f3cfd3aa&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3cfd3aa",
  null
  
)

export default component.exports