<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="30%"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="50px"
        :model="sysItem"
        :rules="ruleValidate">
        <el-form-item :label="$t('sysItem.value')" prop="value">
          <el-date-picker
            v-if="dataType && dataType.toUpperCase() === 'DATE'"
            v-model="sysItem.value"
            type="date"
            value-format="yyyy-MM-dd"
            :clearable="false"></el-date-picker>
          <el-input v-else v-model="sysItem.value"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  const moduleName = "sysItems";
  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        dataType: "",
        sysItem: {
          id: 0,
          value: "",
        },
        ruleValidate: {},
      };
    },
    computed: {
      title() {
        return (this.sysItem.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.sysItem");
      },
    },
    methods: {
      open(id, dataType) {
        this.dialogVisible = true;
        this.dataType = dataType;
        this.sysItem.id = id;
        if (this.sysItem.id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.sysItem.id).then(res => {
          this.sysItem = res.data;
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.sysItem).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.sysItem.id);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
