<template>
  <div>
    <el-form ref="form" v-loading="contentLoading" :model="sysConfig">
      <el-form-item prop="paramKeyValue">
        <vm-rich-text-editor ref="editor" height="415px" class="editor"></vm-rich-text-editor>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </div>
  </div>
</template>

<script>
  import VmRichTextEditor from "@/components/VmRichTextEditor";

  export default {
    components: {VmRichTextEditor},
    data() {
      return {
        submitLoading: false,
        saveDisabled: false,
        contentLoading: false,
        sysConfig: {
          id: 0,
          paramKeyValue: "",
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.contentLoading = true;
        this.$http.get("sysConfig/policyKey").then(res => {
          this.sysConfig = res.data;
          this.$refs.editor.setContent(this.sysConfig.paramKeyValue);
          this.contentLoading = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      handleSubmit() {
        this.sysConfig.paramKeyValue = this.$refs.editor.getContent();
        this.submitLoading = true;
        this.saveDisabled = true;
        this.$http.save("sysConfig", this.sysConfig)
          .then(() => {
            this.submitLoading = false;
            this.saveDisabled = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(() => {
            this.submitLoading = false;
            this.saveDisabled = false;
          });
      },
    },
  };
</script>

<style scoped>
::v-deep .w-e-text-container {
  height: 415px;
}
</style>
